import React, { ErrorInfo } from 'react'

// As of writing this is still not possible to write this as a functional component
// please check updates in the React documentation over here https://reactjs.org/docs/hooks-faq.html#from-classes-to-hooks
class ErrorBoundary extends React.Component {
  public state = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
      console.error('info: ', errorInfo)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="notifly-target-error">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3296 4.66959C11.6225 4.96247 11.6226 5.43734 11.3297 5.73025L9.06037 7.99983L11.3304 10.2696C11.6233 10.5625 11.6233 11.0373 11.3304 11.3303C11.0376 11.6232 10.5627 11.6232 10.2698 11.3303L7.99977 9.06054L5.73031 11.3303C5.43743 11.6232 4.96256 11.6232 4.66965 11.3303C4.37674 11.0374 4.37671 10.5626 4.66959 10.2696L6.93905 7.99994L4.66916 5.73031C4.37625 5.43743 4.37623 4.96256 4.6691 4.66965C4.96198 4.37674 5.43685 4.37671 5.72976 4.66959L7.99965 6.93922L10.269 4.66965C10.5618 4.37674 11.0367 4.37671 11.3296 4.66959Z"
                fill="#EE0000"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75Z"
                fill="#EE0000"
              />
            </svg>
          </div>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
