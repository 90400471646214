import formatDistance from 'date-fns/formatDistance'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import TetherComponent from 'react-tether'
import { enUS, da, fr, es } from 'date-fns/locale'

import { NotiflyEnvironment, EnvironmentContext, useEnvironment } from './environment'
import Settings from './Settings'
import SVG, { Icon } from './SVG'
import { INotification } from './types/notification'
import assertUnreachable from './utils/assertUnreachable'
import ErrorBoundary from './components/ErrorBoundary'
import { IColorConfig } from './types/config'

import './styles/notifly.scss'

// These are locales for date-fns to translate timestamps.
// Notification copy is localized with LocalizeJS in Eduflow.
// This is an undocumented feature for Eduflow only, hance the limited locale set.
const LOCALE_MAP: { [lang: string]: Locale } = {
  da,
  fr,
  es,
}

const Notification = ({
  notification,
  setNotificationAsRead,
}: {
  notification: INotification
  setNotificationAsRead: any
}) => {
  const { language } = useEnvironment()
  const locale = LOCALE_MAP[language] || enUS

  const className = `notifly-notification-list-item${
    notification.isRead ? '' : ' is-active'
  }`

  const handleLink = async (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    await setNotificationAsRead(event)
    if (notification.link) {
      window.location.href = notification.link
    }
  }

  const handleLinkClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    handleLink(event)
  }
  const handleLinkKeyPress = async (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      handleLink(event)
    }
  }
  const setAsRead = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    await setNotificationAsRead(event)
  }
  return (
    <li className={className}>
      <a
        onClick={handleLinkClick}
        onKeyPress={handleLinkKeyPress}
        tabIndex={notification.link ? 0 : -1}
      >
        <div>
          <div className="notifly-notification-item-heading">
            <span className="notifly-notification-item-time" data-ignore>
              {formatDistance(new Date(notification.createdAt), new Date(), {
                addSuffix: true,
                locale,
              })}
            </span>
          </div>
          <div
            className={`notifly-notification-item-text`}
            dangerouslySetInnerHTML={{ __html: notification.text }}
          />
        </div>
      </a>
      <button
        onClick={setAsRead}
        data-tooltip={notification.isRead ? 'Seen' : 'Mark as seen'}
      >
        {notification.isRead ? (
          <SVG icon={Icon.CheckmarkCircle} />
        ) : (
          <SVG icon={Icon.EmptyCircle} Blue />
        )}
      </button>
    </li>
  )
}

type Action =
  | { type: 'update'; notification: INotification }
  | {
      type: 'add' | 'reset' | 'updateMany'
      notifications: readonly INotification[]
    }

interface IState {
  ids: readonly string[]
  store: {
    [id: string]: INotification
  }
}

const init = (notifications: readonly INotification[]) =>
  notifications.reduce(
    (acc, n) => {
      acc.ids = [...acc.ids, n.id]
      acc.store[n.id] = n
      return acc
    },
    { ids: [], store: {} } as IState,
  )

const notificationReducer = (state: IState, action: Action) => {
  const update = (state: IState, notification: INotification) => {
    return {
      ...state,
      store: {
        ...state.store,
        [notification.id]: {
          ...state.store.notification,
          ...notification,
        },
      },
    }
  }
  switch (action.type) {
    case 'add':
      return init(action.notifications)
    case 'update':
      return update(state, action.notification)
    case 'updateMany':
      return action.notifications.reduce(update, state)
    case 'reset':
      return init(action.notifications)
  }
  return assertUnreachable(action)
}

const WidgetContextMenu = ({
  className,
  colorConfig,
}: {
  className?: string
  colorConfig?: IColorConfig
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSettings, setIsSettings] = useState(false)
  const [notificationMap, dispatch] = useReducer(notificationReducer, [], init)
  const environment = useEnvironment()
  const refs = {
    target: useRef<HTMLElement | null>(null),
    element: useRef<HTMLElement | null>(null),
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        ![refs.target.current, refs.element.current].some(
          (el) => el && el.contains(e.target as Node),
        )
      ) {
        setIsOpen(false)
      }
    }

    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }

    window.addEventListener('message', (evt) => {
      if (evt.data === '__NOTIFLY__.open') {
        setIsOpen(true)
      } else if (evt.data === '__NOTIFLY__.close') {
        setIsOpen(false)
      }
    })

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEsc)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEsc)
    }
  }, [refs.target.current, refs.element.current])

  useEffect(() => {
    environment.websocket.onmessage = ({ data }) => {
      const { notifications } = JSON.parse(data)
      dispatch({ type: 'add', notifications })
    }

    return () => {
      environment.websocket.close()
    }
  }, [])

  const initialize = async () => {
    const notifications = await environment.getNotifications()
    dispatch({ type: 'reset', notifications })
  }

  useEffect(() => {
    initialize()
  }, [])

  const notifications = notificationMap.ids.map((id) => notificationMap.store[id])
  const numUnreadNotifications = notifications.filter(({ isRead }) => !isRead).length

  const renderTarget = (ref: React.RefObject<HTMLElement>) => {
    if (!refs.target.current) {
      refs.target = ref
    }
    const handleClick = () => setIsOpen((prevIsOpen) => !prevIsOpen)
    const buttonClassName = `notifly-target${isOpen ? ' is-open' : ''}`

    // prettier-ignore
    const buttonStyle = colorConfig && { __html: `
    #notifly-widget-button {
      ${colorConfig.buttonBackgroundColor ? `--button-background-color: ${colorConfig.buttonBackgroundColor};` : ''}
      ${colorConfig.buttonBackgroundColorHover ? `--button-background-color-hover: ${colorConfig.buttonBackgroundColorHover};` : ''}
      ${colorConfig.buttonStrokeColor ? `--button-stroke-color: ${colorConfig.buttonStrokeColor};` : ''}
      ${colorConfig.buttonStrokeColorActive ? `--button-stroke-color-active: ${colorConfig.buttonStrokeColorActive};` : ''}
    }
    `}
    return (
      <>
        <style dangerouslySetInnerHTML={buttonStyle} />
        <button
          className={buttonClassName}
          ref={ref as React.Ref<HTMLButtonElement>}
          onClick={handleClick}
          aria-label="Notifications"
          id="notifly-widget-button"
        >
          <SVG icon={Icon.Bell} />
          {!isOpen && numUnreadNotifications > 0 ? (
            <span className="notifly-not-read-count">{numUnreadNotifications}</span>
          ) : null}
        </button>
      </>
    )
  }

  const renderElement = (ref: React.RefObject<HTMLElement>) => {
    if (!refs.element.current) {
      refs.element = ref
    }
    if (!isOpen) {
      return null
    }

    const markAllAsSeen = async () => {
      const notificationIds: any = notifications
        .filter(({ isRead }) => !isRead)
        .map((n) => [n.id, ...(n.notificationIds || [])])
      // Cannot use .flat as its not being polyfilled in dist.
      const ns = await environment.markNotificationsAsRead(
        [].concat.apply([], notificationIds),
      )
      dispatch({ type: 'updateMany', notifications: ns })
    }

    const toggleMenu = () => {
      setIsSettings(!isSettings)
    }

    if (isSettings) {
      return (
        <div ref={ref as React.Ref<HTMLDivElement>} className="notifly-widget">
          <Settings toggleMenu={toggleMenu} environment={environment} />
        </div>
      )
    }

    return (
      <div ref={ref as React.Ref<HTMLDivElement>} className="notifly-widget">
        {notifications.length > 0 && (
          <div className="notifly-notification-menu-header">
            <span
              className={
                numUnreadNotifications > 0
                  ? 'notifly-widget-badge'
                  : 'notifly-widget-badge all-read'
              }
            >
              {numUnreadNotifications}
            </span>
            <div className="right">
              <button className="header-button" onClick={toggleMenu}>
                Settings
              </button>
              <span className="header-button-seperator">·</span>
              <button className="header-button" onClick={markAllAsSeen}>
                Mark all as seen
              </button>
            </div>
          </div>
        )}
        {notifications.length > 0 ? (
          <ul className="notifly-notification-list">
            {notifications.map((notification) => {
              const setNotificationAsRead = async () => {
                const ns = await environment.markNotificationsAsRead([
                  notification.id,
                  ...(notification.notificationIds || []),
                ])
                dispatch({ type: 'updateMany', notifications: ns })
              }
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  setNotificationAsRead={setNotificationAsRead}
                />
              )
            })}
          </ul>
        ) : (
          <p className="notifly-notification-list-empty">No notifications yet</p>
        )}
      </div>
    )
  }

  return (
    <TetherComponent
      attachment="top right"
      targetAttachment="bottom right"
      constraints={[{ to: 'window', attachment: 'together', pin: true }]}
      renderTarget={renderTarget}
      renderElement={renderElement}
      className={className}
    />
  )
}

const Widget = ({
  className,
  environment,
  colorConfig,
}: {
  className?: string
  environment: NotiflyEnvironment | { error: string } | null
  colorConfig?: IColorConfig
}) => {
  if (!environment) {
    return (
      <div className="notifly-target-loading">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-5.44116e-07 8C-5.44116e-07 12.4181 3.58144 16 8 16C12.4181 16 16 12.4181 16 8H14.2581C14.2581 11.456 11.4564 14.2581 8 14.2581C4.5436 14.2581 1.7419 11.4564 1.7419 8H-5.44116e-07Z"
            fill="#E6E9EF"
          />
          <path
            d="M16 8C16 3.58187 12.4186 0 8 0C3.58187 0 0 3.58187 0 8H1.7419C1.7419 4.54402 4.5436 1.7419 8 1.7419C11.4564 1.7419 14.2581 4.5436 14.2581 8H16Z"
            fill="#B3BECE"
          />
        </svg>
      </div>
    )
  }

  if ('error' in environment) {
    return (
      <div className="notifly-target-error">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.3296 4.66959C11.6225 4.96247 11.6226 5.43734 11.3297 5.73025L9.06037 7.99983L11.3304 10.2696C11.6233 10.5625 11.6233 11.0373 11.3304 11.3303C11.0376 11.6232 10.5627 11.6232 10.2698 11.3303L7.99977 9.06054L5.73031 11.3303C5.43743 11.6232 4.96256 11.6232 4.66965 11.3303C4.37674 11.0374 4.37671 10.5626 4.66959 10.2696L6.93905 7.99994L4.66916 5.73031C4.37625 5.43743 4.37623 4.96256 4.6691 4.66965C4.96198 4.37674 5.43685 4.37671 5.72976 4.66959L7.99965 6.93922L10.269 4.66965C10.5618 4.37674 11.0367 4.37671 11.3296 4.66959Z"
            fill="#EE0000"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75Z"
            fill="#EE0000"
          />
        </svg>
      </div>
    )
  }

  return (
    <EnvironmentContext.Provider value={{ environment }}>
      <ErrorBoundary>
        <WidgetContextMenu className={className} colorConfig={colorConfig} />
      </ErrorBoundary>
    </EnvironmentContext.Provider>
  )
}

export default Widget
