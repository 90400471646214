import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { makeEnvironment, NotiflyEnvironment } from './environment'
import Widget from './Widget'
import { IColorConfig, INotiflyConfig } from './types/config'

interface INotifly {
  init: (config: INotiflyConfig) => Promise<void>
  open: () => void
  close: () => void
  _environment: NotiflyEnvironment | null
  _config: (config: INotiflyConfig) => void
  _render: () => void
}

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    Notifly?: INotifly
  }
}

// React component, to be used inside React projects.
export const NotiflyContainer = (props: INotiflyConfig) => {
  const [environment, setEnvironment] = useState<
    NotiflyEnvironment | { error: string } | null
  >(null)

  const init = async () => {
    try {
      const env = await makeEnvironment({
        appId: props.appId,
        authCode: props.authCode,
        serverName: props.serverName,
        subscriptionServerName: props.subscriptionServerName,
        language: props.language,
        ...(props.externalId ? { externalId: props.externalId } : {}),
      })
      setEnvironment(env)
    } catch (e) {
      setEnvironment({ error: e.message })
    }
  }

  useEffect(() => {
    init()
  }, [props])

  return <Widget environment={environment} className={props.className} />
}

// This can be used in an arbitrary project. Notifly.render() will render the widget in
// the element passed.
let Config: INotiflyConfig | null = null
export const Notifly: INotifly = {
  init: async function (initConfig: INotiflyConfig) {
    this._config(initConfig)
    if (!Config) {
      throw new Error('Notifly missing config. Call Notifly.config().')
    }
    this._environment = await makeEnvironment({
      appId: Config.appId,
      externalId: Config.externalId,
      authCode: Config.authCode,
      language: Config.language,
      serverName: Config.serverName,
      subscriptionServerName: Config.subscriptionServerName,
    })
    this._render()
  },
  open: function () {
    window.postMessage('__NOTIFLY__.open', window.origin)
  },
  close: function () {
    window.postMessage('__NOTIFLY__.close', window.origin)
  },
  _environment: null,
  _config: function (config: INotiflyConfig) {
    Config = { ...Config, ...config }
  },
  _render: function () {
    if (!this._environment) {
      throw new Error('Notifly not initialized. Call Notifly.init().')
    }

    if (!Config || !Config.elementId) {
      throw new Error('Invalid config. Must provide `elementId`')
    }

    if (!document.getElementById(Config.elementId)) {
      throw new Error(`Cannot find element: ${Config.elementId}`)
    }

    let props: {
      environment: NotiflyEnvironment
      className?: string
      colorConfig?: IColorConfig
    } = {
      environment: this._environment,
      colorConfig: Config.colors,
    }

    if (Config.className) {
      props = {
        ...props,
        className: Config.className,
      }
    }

    ReactDOM.render(<Widget {...props} />, document.getElementById(Config.elementId))
  },
}
