import { Notifly } from './notifly'

// Execute queued commands from `window.Notifly.q`
if (window.Notifly && window.Notifly.q) {
  for (let i = 0; i < window.Notifly.q.length; i++) {
    const [func, ...args] = window.Notifly.q.shift()
    Notifly[func](...args)
  }
}

// Execute any further commands directly
window.Notifly = (func, ...args) => {
  Notifly[func](...args)
}
