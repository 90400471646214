import React from 'react'

import assertUnreachable from './utils/assertUnreachable'

export enum Icon {
  Bell,
  EmptyCircle,
  CheckmarkCircle,
  ArrowLeft,
}

const Path = ({ icon, Blue }: { icon: Icon; Blue?: boolean }) => {
  switch (icon) {
    case Icon.Bell:
      return (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C8.27615 0 8.5 0.223858 8.5 0.5V1.52242C11.3032 1.77505 13.5 4.13107 13.5 7C13.5 9.31708 13.7471 10.6371 13.9766 11.3603C14.0909 11.7205 14.1992 11.9275 14.2667 12.0345C14.3003 12.0878 14.3237 12.1162 14.3327 12.1263L14.3343 12.1282C14.4838 12.2626 14.539 12.4746 14.4717 12.6659C14.4013 12.8661 14.2122 13 14 13H2C1.80875 13 1.63425 12.8909 1.5505 12.719C1.46781 12.5492 1.48787 12.3476 1.60184 12.1976L1.60261 12.1965C1.60515 12.1929 1.61129 12.184 1.62056 12.1693C1.63909 12.1401 1.67019 12.0878 1.70989 12.0091C1.78928 11.8519 1.90345 11.5884 2.02045 11.192C2.25455 10.3987 2.5 9.07246 2.5 7C2.5 4.13107 4.69683 1.77505 7.5 1.52242V0.5C7.5 0.223858 7.72386 0 8 0ZM3.5 7C3.5 4.51481 5.51481 2.5 8 2.5C10.4852 2.5 12.5 4.51481 12.5 7C12.5 9.38025 12.7529 10.8102 13.0234 11.6627C13.0627 11.7866 13.1025 11.8987 13.1422 12H2.80346C2.86094 11.8493 2.92053 11.6751 2.97956 11.475C3.24546 10.574 3.5 9.15021 3.5 7Z"
          />
          <path d="M7.16998 14C7.16998 13.7239 6.94613 13.5 6.66998 13.5C6.39384 13.5 6.16998 13.7239 6.16998 14C6.16998 14.4691 6.42497 14.8554 6.76124 15.1073C7.09737 15.3591 7.53875 15.5 8.00498 15.5C8.47121 15.5 8.91259 15.3591 9.24873 15.1073C9.585 14.8554 9.83998 14.4691 9.83998 14C9.83998 13.7239 9.61613 13.5 9.33998 13.5C9.06384 13.5 8.83998 13.7239 8.83998 14C8.83998 14.0832 8.79612 14.1969 8.64921 14.3069C8.50217 14.4171 8.27605 14.5 8.00498 14.5C7.73391 14.5 7.50779 14.4171 7.36076 14.3069C7.21385 14.1969 7.16998 14.0832 7.16998 14Z" />
        </>
      )
    case Icon.EmptyCircle:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className={Blue ? 'notifly-fill-blue' : 'notifly-fill-grey'}
          d="M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        />
      )
    case Icon.CheckmarkCircle:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="notifly-fill-grey"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM3.75314 8.27834L6.59689 11.0022C6.76774 11.1659 7.04475 11.1659 7.21561 11.0023L12.2469 6.18303C12.4177 6.01936 12.4177 5.75403 12.2469 5.59038L11.6282 4.99774C11.4573 4.83409 11.1803 4.83409 11.0094 4.99774L6.90625 8.92799L4.99056 7.09305C4.81971 6.92939 4.54269 6.92939 4.37184 7.09305L3.75314 7.6857C3.58229 7.84936 3.58229 8.11469 3.75314 8.27834Z"
        />
      )
    case Icon.ArrowLeft:
      return (
        <path
          className="notifly-fill-blue"
          d="M15.9999973,7.99842059 C15.991701,7.7839204 15.9045011,7.53600017 15.7592567,7.37964003 L11.0185185,2.27940741 C10.6972742,1.98711715 10.0812746,1.86645704 9.72222292,2.20440734 C9.36856385,2.53725764 9.37908236,3.18279823 9.7314081,3.51696853 L13.0647396,7.09840178 L0.888888411,7.09840178 C0.397984971,7.09840178 0,7.50135014 0,7.99842059 C0,8.49549104 0.397984971,8.89845141 0.888888411,8.89845141 L13.0647396,8.89845141 L9.7314081,12.4798847 C9.42808974,12.7875349 9.37203051,13.4557855 9.72222292,13.7924458 C10.0723264,14.1290761 10.7104594,14.0215261 11.0185185,13.7174158 L15.7592567,8.61720115 C15.9214788,8.442661 16.0005307,8.23788081 15.9999973,7.99842059 L15.9999973,7.99842059 Z"
          transform="translate(8.000000, 8.000000) rotate(180.000000) translate(-8.000000, -8.000000)"
        />
      )
  }
  return assertUnreachable(icon)
}

const SVG = ({ icon, Blue }: { icon: Icon; Blue?: boolean }) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <Path icon={icon} Blue={Blue} />
    </svg>
  )
}

export default SVG
