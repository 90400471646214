import React from 'react'

import { INotification } from './types/notification'

export type NotiflyEnvironment = {
  getNotifications: () => Promise<readonly INotification[]>
  getEmailPreferences: () => Promise<string>
  websocket: WebSocket
  markNotificationsAsRead: (ids: string[]) => Promise<INotification[]>
  setEmailPreferences: (settings: {
    emailPreferences: string
    externalId: string
  }) => Promise<string>
  language: string
}

export type IMakeEnvironmentParams = {
  appId: string
  externalId?: string
  authCode: string
  language: string | undefined
  serverName: string | undefined
  subscriptionServerName: string | undefined
}

export async function makeEnvironment({
  appId,
  externalId,
  authCode,
  language = 'en',
  serverName = 'https://app.notifly.io',
  subscriptionServerName = 'wss://app.notifly.io',
}: IMakeEnvironmentParams): Promise<NotiflyEnvironment> {
  async function fetchAccessToken(): Promise<string> {
    const response = await fetch(`${serverName}/api/token`, {
      body: JSON.stringify({
        appId,
        externalId,
        authCode,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    if (!response.ok) {
      throw new Error("Couldn't fetch token")
    }
    const { accessToken } = await response.json()
    return accessToken
  }

  const accessToken = await fetchAccessToken()

  const callApi = async (
    endpoint: string,
    method: 'GET' | 'POST' | 'PATCH',
    body?: BodyInit,
  ) => {
    const response = await fetch(`${serverName}${endpoint}`, {
      body,
      headers: {
        Authorization: `Token ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method,
      mode: 'cors',
    })
    if (!response.ok) {
      throw new Error('Fetch failed')
    }

    return response.json()
  }

  const websocketPromise = new Promise<WebSocket>((resolve) => {
    const ws = new WebSocket(
      `${subscriptionServerName}/ws/notifications?token=${accessToken}`,
    )
    ws.onopen = () => resolve(ws)
  })

  const websocket = await websocketPromise

  return {
    getNotifications: () => callApi('/api/notification', 'GET'),
    websocket,
    markNotificationsAsRead: (notificationIds: string[]) =>
      callApi(
        `/api/notification`,
        'PATCH',
        JSON.stringify({ notificationIds, isRead: true }),
      ),
    getEmailPreferences: () => callApi('/api/user', 'GET'),
    setEmailPreferences: (settings: { emailPreferences: string; externalId: string }) =>
      callApi('/api/user', 'PATCH', JSON.stringify(settings)),
    language,
  }
}

export const EnvironmentContext = React.createContext<{
  environment: NotiflyEnvironment | null
}>({
  environment: null,
})

export const useEnvironment = () => {
  const { environment } = React.useContext(EnvironmentContext)

  if (!environment) {
    throw new Error(
      'Environment not defined. Make sure your component has EnvironmentContext.Provider as parent',
    )
  }

  return environment
}
