import React, { useEffect, useState, useCallback } from 'react'

import SVG, { Icon } from './SVG'

interface IProps {
  toggleMenu: () => void
  environment: any
}

const Settings = ({ toggleMenu, environment }: IProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [emailSettings, setEmailSettings] = useState()

  const loadEmailPreferences = async () => {
    const emailPreferences = await environment.getEmailPreferences()
    setEmailSettings(emailPreferences[0])
  }
  const updateEmailPreferences = async (settings: {
    emailPreferences: string
    externalId: string
  }) => {
    const newEmailPreferences = await environment.setEmailPreferences(settings)
    setEmailSettings(newEmailPreferences)
  }

  useEffect(() => {
    loadEmailPreferences()
  }, [])

  useEffect(() => {
    if (emailSettings) {
      setIsLoading(false)
    }
  }, [emailSettings])

  const changeEmailPreference = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    if (target.value !== emailSettings.emailPreferences) {
      updateEmailPreferences({
        emailPreferences: target.value,
        externalId: emailSettings.externalId,
      })
    }
  }
  if (isLoading) {
    return (
      <>
        <div className="notifly-notification-menu-header">
          <button className="notifly-back-button" onClick={toggleMenu}>
            <SVG icon={Icon.ArrowLeft} />
          </button>
          <span className="notifly-submenu-title">Settings</span>
        </div>
        <div className="notifly-menu-content" />
      </>
    )
  }
  return (
    <>
      <div className="notifly-notification-menu-header">
        <button className="notifly-back-button" onClick={toggleMenu}>
          <SVG icon={Icon.ArrowLeft} />
        </button>
        <span className="notifly-submenu-title">Settings</span>
      </div>
      <div className="notifly-menu-content">
        <p className="notifly-menu-heading">Emails</p>
        <p className="notifly-menu-description">
          Your account is registered to{' '}
          <var data-var="email">{emailSettings.email}</var>
        </p>
        {emailSettings ? (
          <>
            <div className="notifly-radio-button">
              <input
                type="radio"
                onClick={changeEmailPreference}
                checked={emailSettings.emailPreferences === 'ALL_EMAILS'}
                value="ALL_EMAILS"
                readOnly
                id="notifly-all-notifications"
              />
              <div className="notifly-radio-dot" />
              <label htmlFor="notifly-all-notifications">
                Receive all notifications on email if they are not seen inside the app
              </label>
            </div>
            <div className="notifly-radio-button">
              <input
                type="radio"
                onClick={changeEmailPreference}
                checked={emailSettings.emailPreferences === 'NO_NOTIFICATIONS'}
                value="NO_NOTIFICATIONS"
                readOnly
                id="notifly-no-notifications"
              />
              <div className="notifly-radio-dot" />
              <label htmlFor="notifly-no-notifications">
                Never receive any notifications on email
              </label>
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

export default Settings
